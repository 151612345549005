import logo from './logo.svg';
import './App.css';
import MainHeader from './components/MainHeader';
import Content from './components/Content';
import Dailyform from './components/Dailyform';
import DisplayDaily from './components/DisplayDaily';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return ( <>
    <MainHeader/>

    <Router>
    <Routes>
      <Route path="/" element={<Dailyform />} />
      <Route path="/6789373975" element={<DisplayDaily />} />
      <Route path="/contact" element={<Content />} />
    </Routes>
  </Router>
  </>
  );
}

export default App;
