import React, { useState, useEffect } from "react";
import sheetdb from "sheetdb-node";

const Dailyform = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const submitSheet = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const rows = [
      {
        Date: formData.get("Date"),
        DropAmount: formData.get("DropAmount"),
        CashBack: formData.get("CashBack"),
        SafeBalance: formData.get("SafeBalance"),
        LottoPayout: formData.get("LottoPayout"),
        MachineIn: formData.get("MachineIn"),
        MachineOut: formData.get("MachineOut"),
        Profit: formData.get("MachineIn") - formData.get("MachineOut"),
        BankBalance: formData.get("BankBalance"),
        Name: formData.get("Name"),
        Shift: formData.get("Shift"),
      },
    ];


    const sendDataToServer = async () => {
      fetch("https://sheetdb.io/api/v1/wiscjd5h7qw27") // Replace with your API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });

    let clearform = document.getElementById('myForm');
    clearform.reset();
    
    };

    // create a config file
    var config = {
      address: "wiscjd5h7qw27",
    };
    // Create new client
    var client = sheetdb(config);

    console.log("The value of rows is");
    console.log(rows);
    client.create(rows).then(
      function (data) {
        console.log(data);
        alert("form submitted Successfully");
      },
      function (err) {
        console.log(err);
      }
    );
    sendDataToServer();
  };
 

  return (
    <div className="container">
      <h3>Daily Paperwork</h3>
      <form id="myForm" onSubmit={submitSheet}>
        <div className="row">
          <div class="col">
            <label for="Date">Date</label>
            <input
              type="text"
              class="form-control"
              placeholder="Date"
              name="Date"
            />
          </div>
          <div className="col">
            <label for="DropAmount">Drop Amount</label>{" "}
            <input
              type="number"
              class="form-control"
              placeholder="DropAmount"
              name="DropAmount"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="CashBack">Cash Back</label>
            <input
              type="number"
              class="form-control"
              placeholder="Add all your cashback receipt"
              name="CashBack"
            />
          </div>
          <div className="col">
            <label for="SafeBalance">Safe Balance</label>
            <input
              type="number"
              class="form-control"
              placeholder="Dont forget to add safe balance"
              name="SafeBalance"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="LottoPayout">Lotto Payout</label>
            <input
              type="number"
              class="form-control"
              placeholder="LottoPayout"
              name="LottoPayout"
            />
          </div>
          <div className="col">
            <label for="MachineIn">Machine In</label>
            <input
              type="number"
              class="form-control"
              placeholder="MachineIn"
              name="MachineIn"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="MachineOut">Machine Out</label>
            <input
              type="number"
              class="form-control"
              placeholder="MachineOut"
              name="MachineOut"
            />
          </div>
          <div className="col">
            <label for="MachineOut">Bank Balance</label>
            <input
              type="number"
              class="form-control"
              placeholder="Initial balance - lotto payout - cashback"
              name="BankBalance"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label for="Name">Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter your Name"
              name="Name"
            />
          </div>
          <div className="col">
            <label for="Shift">Shift</label>
            <input
              type="text"
              class="form-control"
              placeholder="Shift"
              name="Shift"
            />
          </div>
        </div>
        <br />
        <input type="submit" />
      </form>
    </div>
  );
};

export default Dailyform;
