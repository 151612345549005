import React from 'react'
import './Content.css'
import Dailyform from './Dailyform'


const Content = () => {
  return (
    <div class="container text-center">
  <div class="row">
    <div class="col-8 text-bg-light p-3">
    <Dailyform/>
    </div>
    <div class="col-4">

    </div>
  </div>
</div>
  )
}

export default Content