import React, { useState, useEffect } from "react";

const DisplayDaily = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch("https://sheetdb.io/api/v1/wiscjd5h7qw27")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []); // The empty array ensures this effect runs only once after the initial render

  // Optional: Create table headers based on the keys of the first object (if data exists)
  const headers = data.length > 0 ? Object.keys(data[0]) : [];
  return <div className="container">
     <table className="table">
      <thead>
        <tr>
          {headers.map((header) => (
            <th scope="col" key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {headers.map((header) => (
              <td scope="row" key={`${index}-${header}`}>{item[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>;
};

export default DisplayDaily;
